/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Table from '../global/Table';
import TableDropDown from '../global/TableDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import Select from '../global/Select';
import { AuthContext } from "../../context/authContext";
import { createUserTypeAndRoleObject, capitaliseFirstCase } from "../../utils/index";
import { sysObjects } from "../../constants/sysObjectList";
import { NavContext } from '../../context/navContext';
import Comment from '../global/Comment';
import {
  NOTIFICATION_STATUS_APPROVED,
  NOTIFICATION_STATUS_PENDING,
  NOTIFICATION_STATUS_REJECT,
  NOTIFICATION_STATUS_REVISION,
  TEMP_DUTYHOLDER_ID
} from '../../constants/';

const dropdownListItems = (dhId) => {
  const listItems = [
    {
      id: 1,
      name: 'Access Control',
      link: dhId && dhId !== "" ? `/dutyHolder/${dhId}/users/access` : "/users/access",
      state: { formMode: 'edit' }
    },
    {
      id: 2,
      name: 'Signature',
      link: dhId && dhId !== "" ? `/dutyHolder/${dhId}/users/signature` : "/users/signature",
      state: { formMode: 'edit' }
    },
  ]
  return listItems;
}


const ARCHIVE_USER = gql`
    mutation ArchiveUser($_id: String!, $msId: String!) {
        archiveUser(_id: $_id, msId: $msId) {
            user_email
            user_archived
        }
    }
`;

const UNARCHIVE_USER = gql`
    mutation UnArchiveUser($_id: String!, $msId: String!) {
        unArchiveUser(_id: $_id, msId: $msId) {
            user_email
            user_archived
        }
    }
`;

const USERS_BY_DH_ID_QUERY = gql`
query UsersByDhId($dhId: ID, $isArchived: Boolean!) {
  users_by_dh_id(dhId: $dhId, isArchived: $isArchived) {
        _id
        user_ms_oid
        user_type {
            _id
            user_type_display_name
        }
        user_email
        user_fname
        user_lname
        user_job_title
        user_location
        user_office_tel
        user_mobile
        user_notes
        user_profile_image
        user_status
        user_archived
    }
}`;

function useQueryString() {
  return new URLSearchParams(useLocation().search);
}

function Users(props) {
  const queryString = useQueryString();
  const [showHelp, setShowHelp] = useState(false);
  const [showArchivedUsers, setShowArchivedUsers] = useState(false);
  const [userType, setUserType] = useState(queryString.get('userType') || '');
  const [userList, setUserList] = useState([]);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const [drDnDutyholder, setDrDnDutyholder] = useState({});
  const [drDnInstallation, setDrDnInstallation] = useState({});
  const [drDnSece, setDrDnSece] = useState({});

  const { dhId = "" } = useParams();

  const history = useHistory();

  const navContext = useContext(NavContext);
  const authContext = useContext(AuthContext);

  const currentPath = useLocation().pathname;

  const { location: { pathname, state = "" }, match: { url = "" } } = props ? props : {};

  const [users_by_dh_id, { loading: usersByDhIdLoading, error: usersByDhIdError, data: usersByDhIdData }] = useLazyQuery(USERS_BY_DH_ID_QUERY);

  const [archiveUser, { data: archiveUserData }] = useMutation(ARCHIVE_USER);
  const [unArchiveUser] = useMutation(UNARCHIVE_USER);

  useEffect(() => {
    if (state === "archived") {
      setShowArchivedUsers(true);
    }
  }, []);

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    const { dutyHolder, installation, sece } = navContext;
    setDrDnDutyholder(dutyHolder);
    setDrDnInstallation(installation);
    setDrDnSece(sece);
  }, [navContext]);

  useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
    }
  }, [userTypeAndRoleObject]);

  useEffect(() => {
    state === "archived" ? setShowArchivedUsers(true) : setShowArchivedUsers(false);
  }, [state]);

  useEffect(() => {
    //let dh_id = userType === USER_TYPE_DUTY_HOLDER_ID && drDnDutyholder.id ? drDnDutyholder.id : "";
    let dh_id = drDnDutyholder.id && drDnDutyholder.id !== "" ? drDnDutyholder.id : "";
    
    if (dhId && dhId !== "") {
      dh_id = dhId
    }

    let payload = {
      ...(dh_id && dh_id !== "" && { dhId: dh_id }),
      isArchived: showArchivedUsers
    }
    users_by_dh_id({ variables: { ...payload }, errorPolicy: 'all' })
  }, [showArchivedUsers, userType, pathname, drDnDutyholder]);

  useEffect(() => {
    if (usersByDhIdData && usersByDhIdData.users_by_dh_id) {
      let tempResults = userType === "" ? usersByDhIdData.users_by_dh_id : 
                                          usersByDhIdData.users_by_dh_id.filter(user => user.user_type._id === userType)
      setUserList(tempResults);
    }
  }, [usersByDhIdData]);

  useEffect(() => {
    if (archiveUserData) {
      history.push('/users/archived');
    }
  }, [archiveUserData]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: originalRow => `${originalRow['user_fname']} ${originalRow['user_lname']}`,
      },
      {
        Header: 'Email',
        accessor: 'user_email',
      },
      {
        Header: 'User Type',
        accessor: 'user_type.user_type_display_name',
      },
      {
        Header: 'Office Tel.',
        accessor: 'user_office_tel',
      },
      {
        Header: 'Roles',
        accessor: 'user_roles',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: user => {
          if (user.value.user_status === NOTIFICATION_STATUS_APPROVED ||
            user.value.user_status === NOTIFICATION_STATUS_PENDING ||
            user.value.user_status === NOTIFICATION_STATUS_REJECT ||
            user.value.user_status === NOTIFICATION_STATUS_REVISION
          ) {
            return (
              <div className="text-right">

                <Link
                  to={{
                    pathname: dhId && dhId !== "" ? `/dutyHolder/${dhId}/user/view/${user.value._id}` : `/user/view/${user.value._id}`,
                  }}
                  className="button-red mb-1 mr-1 text-xs"
                >
                  View
                </Link>

                {user && 
                user.value && 
                user.value.userTypeAndRoleObject && 
                user.value.userTypeAndRoleObject.userType && 
                user.value.userTypeAndRoleObject.userType === "ABS" &&
                user.value.userTypeAndRoleObject.userRole && 
                user.value.userTypeAndRoleObject.userRole !== "Verifier" &&
                  <div className="inline">
                    {!user.value.user_archived &&
                      <Link
                        to={{
                          pathname: dhId && dhId !== "" ? `/dutyHolder/${dhId}/user/edit/${user.value._id}` : `/user/edit/${user.value._id}`,
                          state: { formMode: 'edit' }
                        }}
                        className="button-red mb-1 mr-1 text-xs"
                      >
                        Edit
                      </Link>
                    }
                    {!user.value.user_archived &&
                      <button
                        className="button-red mb-1 mr-1 text-xs"
                        type="button"
                        onClick={() => {
                          archiveUser({
                            variables: {
                              _id: user.value._id,
                              msId: user.value.user_ms_oid
                            }
                          }).then(res => {
                            history.push('/users');
                          })
                        }}
                      >
                        Archive
                      </button>
                    }

                    <Comment sysObjName={sysObjects.user}
                      sysObjRecordId={user.value._id}
                      buttonName={"Comments"}
                      buttonClassName={"button-red mb-1 mr-1 text-xs"} />

                    {user.value.user_archived &&
                      <button
                        className="button-red mb-1 mr-1 text-xs"
                        type="button"
                        onClick={() => {
                          unArchiveUser({
                            variables: {
                              _id: user.value._id,
                              msId: user.value.user_ms_oid
                            }
                          }).then(res => {
                            history.push('/users');
                          })
                        }}
                      >
                        Unarchive
                      </button>
                    }
                  </div>
                }

                {user &&
                  user.value &&
                  user.value.userTypeAndRoleObject &&
                  user.value.userTypeAndRoleObject.userType &&
                  user.value.userTypeAndRoleObject.userType === "ABS" &&
                  user.value.userTypeAndRoleObject.userRole &&
                  user.value.userTypeAndRoleObject.userRole !== "Verifier" &&
                  <TableDropDown title="More" id={user.value._id} items={dropdownListItems(dhId)} />
                }

              </div>
            );
          }

          return null;
        }
      },
    ],
    [showArchivedUsers, dhId]
  )

  if (usersByDhIdLoading) {
    return (
      <PageSpinner />
    )
  }

  if (usersByDhIdError) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">User Management <FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <div>
            {showArchivedUsers === false ?
              <div>
                <p className="mt-3">A table of active users.</p>
                <p>Use the drop-down menu to filter between ABS and Duty Holder users and the ‘Add User’ button to create a new user.</p>
              </div>
              : <div>
                <p className="mt-3">A table of archived users.</p>
                <p>Use the drop-down menu to filter between ABS and Duty Holder users.</p>
              </div>
            }

          </div>
        }

      </div>

      <h2 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h2>

      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "ABS" &&
        drDnDutyholder.id && drDnInstallation.id) && <h3 className="mb-6 font-bold text-sm text-red-900">Duty Holder:  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h3>}
      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "Duty Holder" &&
        drDnInstallation.id) && <h3 className="mb-6 font-bold text-sm text-red-900"> Installation: {drDnInstallation.name}</h3>}
      {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h3 className="mb-6 font-bold text-sm text-red-900"> Duty Holder / Installation : All</h3>}

      {/* <h3 className="mb-6 font-bold text-sm text-red-900">Asset Group Name - Installation 01 - User Management</h3> */}

      {/* dhId && dhId !== "" &&
        <Link
          to={{
            pathname: '/dutyholders',
          }}
          className="button-red mr-2 mb-2 py-3 px-5"
        >
          Back
        </Link>
        */}

      {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" && userTypeAndRoleObject.userRole !== "Verifier" &&
        <Link
          to={{
            pathname: dhId && dhId !== "" ? `/dutyHolder/${dhId}/user/add` : '/user/add',
            state: { formMode: 'add' }
          }}
          className="button-red mr-2 mb-2 py-3 px-5"
        >
          Add User
        </Link>
      }

      <Link to={{
        pathname: showArchivedUsers ? url : (url + "/archived"),
        state: showArchivedUsers ? "" : "archived"
      }}
        className="button-red mr-2 mb-2 py-3 px-5"
        onClick={() => { setShowArchivedUsers(!showArchivedUsers) }}
      >{showArchivedUsers ? "Active Users" : "Archived Users"}</Link>

      {dhId === "" &&
        <Link
          to={{
            pathname: `/abs/${TEMP_DUTYHOLDER_ID}/locations`,
          }}
          className="button-red mb-2 mr-2 py-3 px-5">Locations</Link>
      }

      <Link to={dhId && dhId !== "" ? `/dutyHolder/${dhId}/users/histories` : "/users/histories"} className="button-red mb-2 mr-2 py-3 px-5">History</Link>

      {dhId === "" && <Select
        title="Show All"
        name="select_user_type"
        dataKey="user_type"
        accessor="user_type_display_name"
        customClassName="form-control w-48 py-3 mb-2 sm:mb-0"
        ariaLabel="User Type"
        onChange={e => {
          const val = e.target.value;

          if (val !== 'Show All') {
            setUserType(val);
            history.push({ pathname: `${url}/${showArchivedUsers ? "archived/" : ""}?userType=${val}`, state: showArchivedUsers ? "archived" : "" });
          } else {
            setUserType('');
            history.push({ pathname: currentPath, state: showArchivedUsers ? "archived" : "" });
          }
        }}
        value={userType}
        query={gql`query {
                      user_type {
                        _id
                        user_type_display_name
                      }
                  }
                `}
      />}

      <Table columns={columns} data={formatUsersData(userList, userTypeAndRoleObject)} />
    </div>
  )
}

function formatUsersData(users, userTypeAndRoleObject) {
  let newUsersList = [];

  if (users && users.length) {
    let tempUsersList = users;
    tempUsersList.forEach(user => {
      let tempStatus = user.user_status && user.user_status !== "" ? capitaliseFirstCase(user.user_status) : "N/A";
      let newUser = { ...user, status: tempStatus, userTypeAndRoleObject: userTypeAndRoleObject };
      newUsersList.push(newUser);
    })
  };

  return newUsersList;
}

export default Users;