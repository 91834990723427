/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import Header from './components/global/Header';
import Nav from './components/global/Nav';
import Footer from './components/global/Footer';
import { AuthContext } from "./context/authContext";
import { NavContextProvider } from "./context/navContext";
import { PageContextProvider } from "./context/pageContext";
import { DashboardContextProvider } from "./context/dashboardContext";
import { VsReportContextProvider } from "./context/vsReportContext";
import initFontAwesome from './assets/fontawesome';
import { gql, useQuery, useMutation } from "@apollo/client";

import Routes from './Routes';

initFontAwesome();

const USER_QUERY = gql`
    query GetUser($msId: String!){
        user_by_msid(msId: $msId) {
            _id
            user_ms_oid
            user_type {
                _id
                user_type_display_name
            }
            user_role {
                _id
                user_type
                user_type_id
                user_role_display
                user_role_code
            }
            user_email
            user_fname
            user_lname
            user_job_title
            user_location
            user_office_tel
            user_mobile
            user_notes
            user_profile_image
            user_is_super_user
            user_duty_holder {
                _id
                dh_name
                dh_code
            }
            user_duty_holder_location {
                _id
                location_name
            }
            user_home_dir
        }
    }`;

const CREATE_ACCESS_LOG = gql`
    mutation CreateAccessLog($al: CreateAccessLog!) {
        createAccessLog(al: $al) {
            _id
            success
            status_code
            message
        }
    }
`;

const App = (props) => {
  const [msId, setMsId] = useState('');
  const [accountInfo, setAccountInfo] = useState({});

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const authContext = useContext(AuthContext);

  const { data: userByMsIdData, refetch } = useQuery(USER_QUERY, { variables: { msId: msId }, errorPolicy: 'all' });
  const [createAccessLog, { data: createAccessLogData }] = useMutation(CREATE_ACCESS_LOG);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const msalUserAccount = accounts[0];
      setAccountInfo(msalUserAccount);
    }
  }, [accounts])

  useEffect(() => {
    if (accountInfo && accountInfo.localAccountId) {
      setMsId(accountInfo.localAccountId);
    }
  }, [accountInfo])

  useEffect(() => {
    refetch();
  }, [authContext.updateUser])

  useEffect(() => {
    if (userByMsIdData && userByMsIdData.user_by_msid) {
      authContext.setUser(userByMsIdData.user_by_msid);
      handleAccessLog(userByMsIdData.user_by_msid, isAuthenticated);
    }
  }, [userByMsIdData])

  useEffect(() => {
    if (createAccessLogData && createAccessLogData.createAccessLog) {
      //const { success, message } = createAccessLogData.createAccessLog;
      // if (!success) {
      //   alert(`${message}`);
      // }
    }
  }, [createAccessLogData])

  const handleAccessLog = (user, isAuthenticated) => {
    if (user) {
      const { _id = "", user_email = "", user_type: { user_type_display_name = "" } } = user;
      const payload = {
        al_user_id: _id,
        al_user_type: user_type_display_name,
        al_user_ip_address: "Not Available",
        al_user_email: user_email,
        al_login_status: isAuthenticated ? "Success" : "Fail",
        al_added_date: new Date()
      };
      createAccessLog({ variables: { al: payload } });
    };
  }

  const logoutHandler = () => {
    localStorage.removeItem('dropDownSelection');
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }

  return (
    <Router>
      <Header logoutHandler={logoutHandler} accountInfo={accountInfo} />
      <NavContextProvider>
        <PageContextProvider>
          <DashboardContextProvider>
            <VsReportContextProvider>
              <div className="flex-row lg:flex">
                <Nav />
                <Routes />
              </div>
            </VsReportContextProvider>
          </DashboardContextProvider>
        </PageContextProvider>
      </NavContextProvider>
      <Footer />
    </Router>
  )
}

export default App;